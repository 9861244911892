// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vq43SlnPf", "uluUlabfo"];

const serializationHash = "framer-QVZNL"

const variantClassNames = {uluUlabfo: "framer-v-1gpvrou", vq43SlnPf: "framer-v-z3srd0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "vq43SlnPf", "Variant 2": "uluUlabfo"}

const getProps = ({colorOff, colorOn, height, id, width, ...props}) => { return {...props, tV6RQgjy4: colorOn ?? props.tV6RQgjy4 ?? "rgb(133, 222, 255)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vq43SlnPf", ZyC20jIX2: colorOff ?? props.ZyC20jIX2 ?? "rgba(133, 222, 255, 0.5)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;colorOn?: string;colorOff?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tV6RQgjy4, ZyC20jIX2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vq43SlnPf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1q2qwfv = activeVariantCallback(async (...args) => {
await delay(() => setVariant("uluUlabfo"), 1000)
})

const onAppearw4n2o1 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("vq43SlnPf"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1q2qwfv, uluUlabfo: onAppearw4n2o1})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-z3srd0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"vq43SlnPf"} ref={ref ?? ref1} style={{backgroundColor: tV6RQgjy4, borderBottomLeftRadius: 84, borderBottomRightRadius: 84, borderTopLeftRadius: 84, borderTopRightRadius: 84, boxShadow: "0px 0px 14px 1px rgb(133, 222, 255)", ...style}} variants={{uluUlabfo: {backgroundColor: ZyC20jIX2, boxShadow: "none"}}} {...addPropertyOverrides({uluUlabfo: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QVZNL.framer-1p5rboo, .framer-QVZNL .framer-1p5rboo { display: block; }", ".framer-QVZNL.framer-z3srd0 { height: 5px; overflow: hidden; position: relative; width: 5px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 5
 * @framerIntrinsicWidth 5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uluUlabfo":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tV6RQgjy4":"colorOn","ZyC20jIX2":"colorOff"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerK5pGMh2ba: React.ComponentType<Props> = withCSS(Component, css, "framer-QVZNL") as typeof Component;
export default FramerK5pGMh2ba;

FramerK5pGMh2ba.displayName = "on/off Copy";

FramerK5pGMh2ba.defaultProps = {height: 5, width: 5};

addPropertyControls(FramerK5pGMh2ba, {variant: {options: ["vq43SlnPf", "uluUlabfo"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, tV6RQgjy4: {defaultValue: "rgb(133, 222, 255)", title: "color/on", type: ControlType.Color}, ZyC20jIX2: {defaultValue: "rgba(133, 222, 255, 0.5)", title: "color/off", type: ControlType.Color}} as any)

addFonts(FramerK5pGMh2ba, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})